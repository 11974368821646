// .has-smooth-scroll {

// }

.has-scroll-smooth body {
    overflow: hidden;
}

.pageDesc {
    position: fixed;
    top: 10rem;
    color: var(--color-black);
    width: 100%;
    left: 0;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 768px) {
        position: absolute;
    }

    h1 {
        font-size: 15vw;
        font-weight: bold;
        overflow-y: hidden;

        > div {
            overflow-y: hidden;
        }
        .char {
            display: inline-block;
            line-height: 1.1;
            margin-bottom: -2vw;
        }

        @media screen and (min-width: 1024px) {
            font-size: 10vh;

        }
    
    
    }
    p {
        font-family: var(--font-f-sans);
        font-size: 1.12rem;
        overflow-y: hidden;

        > div {
            overflow-y: hidden;
        }
        
        .char {
            display: inline-block;
            line-height: 1.1;
            margin-bottom: -2vw;
        }
    }
}

.home {
    position: relative;
    height: 100vh;
    padding: 5rem;
    padding-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media screen and (min-width: 768px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    @media screen and (min-width: 1024px) {
        padding-left: 1rem;
    }
    @media screen and (min-width: 1200px) {
        padding-left: 3rem;
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        
        
        .covers{
            display: flex;
            padding-left: calc(50vw - 15rem);
            padding-right: calc(50vw - 8rem);

            @media screen and (max-width: 768px) and (orientation: portrait) {
                flex-direction: column;
                padding: 0;
                position: relative;
                top: 60vh;
                padding-bottom: 10vh;

                .cover__item-link {
                    margin-top: 10vh;
                }
                
            }
            
            // figure.cover {
            //     flex: 0 0 auto;
            //     max-width: 20rem;
            //     margin: 3rem;
            //     margin-bottom: 0;   

            //     .cover__img {
            //         width: 100%;
            //         height: 100%;
            //         object-fit: cover;
            //     }
            // }

            .cover__item {
                display: block;
                margin: 0;
                display: grid;
                grid-template-areas:
                    '... ...'
                    'cover-image cover-image'
                    'cover-image cover-image';
                grid-template-columns: 8rem 25vh;
                grid-template-rows: 4rem 45vh 3rem;
            
                &-link {
                    text-decoration: none;
                }
                &-img {
                    grid-area: cover-image;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    transform-origin: 50% 100%;
                    will-change: transform;
                    cursor: pointer;
                    z-index: 2;
                }
                &-imgInner {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 25%;
                    width: calc(100% + 10vw);
                    margin-left: -5vw;
                    height: 100%;
                    will-change: transform;
                }
                &-caption {
                    grid-area: 1 / 1 / 4 / 3;
                    display: grid;
                    grid-template-areas:
                        'cover-number cover-number'
                        'cover-link ...'
                        'cover-link cover-tags';
                    grid-template-columns: 8rem auto;
                    grid-template-rows: 4rem auto 3rem;
                    position: relative;
                    z-index: 1;
                }
                &-number {
                    grid-area: cover-number;
                    font-size: 30vw;
                    // font-family: var(--font-f-serif);
                    justify-self: center;
                    color: var(--color-black);
                    cursor: pointer;
                    color: transparent;
                    -webkit-text-stroke: 1px #645c5b;
                    // text-stroke: 1px #645c5b;
                    -webkit-text-fill-color: transparent;
                    // text-fill-color: transparent;
            
                    position: relative;
                    top: -50px;
                    
                    @media screen and (min-width: 768px) {
                        font-size: 15vw;
                        
                    }
                    @media screen and (min-width: 1024px) {
                        font-size: 10vw;
            
                    }
                }
            }
        }
    }
}



// [data-scroll-direction="horizontal"] [data-scroll-container] {
//     height: 100vh;
//     display: inline-block;
//     white-space: nowrap;
// }
.home[data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap;
}


// Components


// Header Navigation
header {
    --header-padding: 1.5rem;
    // background: var(--color-black);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: var(--header-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    border-bottom: 1px solid rgba(black, 0.24);
    background: var(--color-offwhite);

    
    @media screen and (min-width: 768px) {
        --header-padding: 1.5rem 3rem;
    }
    @media screen and (min-width: 1024px) {
        --header-padding: 2rem 4rem;
    }

    .header__logo {
        max-height: 40px;
        max-width: 120px;
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .toggleMenu {
        // color: white;
        cursor: pointer;
    }

    // Side Panel
    &.book {
        height: auto;
        top: 0;
        left: 0;
        flex-direction: row;
        padding: 1.5rem;
        background: transparent;
        position: absolute;
        border-bottom: none;
        // .header__logo {
            // max-height: 1rem;

            // @media screen and (min-width: 768px) {
            //     max-height: 40px;
            // }
        // }
        

        .toggleMenu {
            filter: invert(1);
        }
        
        @media screen and (min-width: 768px) {
            position: fixed;
            // border-bottom: none;
            width: auto;
            height: 100%;
            top: 0;
            left: 0;
            flex-direction: column;
            border-bottom: none;
            border-right: 1px solid rgba(255, 255, 255, 0.24);
            padding: 1.5rem;

            background: rgba(255, 255, 255, 0.01);
            backdrop-filter: blur(10px);
        }
    }
}




.has-scroll-init:not(.has-scroll-smooth) .homepage{
    // background: red;

    .pageDesc {
        position: absolute;
    }
    .home {
        width: 100%;
        
        .content {
            width: 100%;

            .covers {
                flex-direction: column;
                padding: 0;
                position: relative;
                top: 60vh;
                padding-bottom: 10vh;

                .cover__item-link {
                    margin-top: 10vh;
                }
            }
        }
    }
}