// Default 
html, body{
    height: 100%;
  //   font-family: 'helvetica neue';
  }
  
  body{
    height: 100%;
    // overflow-y: scroll;
    padding: 0;
    margin: 0;
  //   background-color: #111;
    // user-select: none;
  }
  
  h1, h2{
    font-weight: normal;
  }
  
  * {
    box-sizing: border-box;
  }
  
  figure{
    padding: 0;
    margin: 0;
  }
  
  
  
  // Classes
  .scroll{
    cursor: grab;
  }
  
  .scroll-content{
    display: flex;
    white-space: nowrap;
    position: relative;
    height: 100vh;
    
    &--last{
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  .slide{
    display: flex;
    width: 50vw;
    height: 100%;
    padding: 20vh 0;
    
    &--1{
      align-items: flex-start;
    }
    
    &--2{
      align-items: flex-end;
    }
    
    &--3{
      align-items: center;
    }
    
    
    &:last-child{
      width: 65vw;
      padding-right: 15vw;
    }
    
    &__inner{
      position: relative;
      padding-left: 15vw;
      width: 100%;
    }
    
    &__sub-title{
      position: absolute;
      top: 15%;
      left: 5vw;
      // color: rgba(#fff, 0.5);
      font-size: 1vw;
    }
    
    &__title{
      position: absolute;
      top: 7.5%;
      left: 7.5vw;
      // color: #fff;
      font-size: 4vw;
      z-index: 2;
      overflow: hidden;
    }
    
    &__project{
      // color: #fff;
      position: absolute;
      top: 100%;
      right: 5%;
      font-size: 1.15vw;
      padding-top: 1.5vw;
    }
    
    &__img{
      position: relative;
      overflow: hidden;
      padding-top: 65%;
      width: 100%;
      
      figure{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        img{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  
  // Should I move this to the top?
  .testing img{
    display: block;
    width: 100%;
    height: auto;
  }
  
  .scrollbar{
    position: absolute;
    bottom: 7.5%;
    left: 20%;
    right: 20%;
    height: 1px;
    background-color: rgba(#fff, 0.25);
    
    &__handle{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      transform-origin: left;
      background-color: #fff;
    }
  }
  
  // .mask{
  //   display: flex;
  //   flex-direction: column;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 1000;
  //   visibility: hidden;
  //   opacity: 0;
    
  //   &__slice{
  //     flex: 1;
  //     background-color: #000;
  //   }
    
  //   &__inner{
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translateX(-50%) translateY(-50%);
  //   }
    
  //   &-line{
  //     position: relative;
  //     transform-origin: left;
  //     width: 20rem;
  //     height: 2px;
  //     overflow: hidden;
  //     background-color: rgba(#fff, 0.25);
  //     visibility: hidden;
  //     opacity: 0;
      
  //     &__inner{
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       height: 100%;
  //       background-color: #fff;
  //       transform-origin: left;
  //     }
  //   }
  // }
  
  // .resize{
  //   display: none;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #000;
  //   z-index: 9999;
    
  //   &__inner{
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translateX(-50%) translateY(-50%);
  //     color: #fff;
  //     text-align: center;
  //     text-decoration: none;
  //   }
    
  //   span{
  //     color: rgba(#fff, 0.5);
  //   }
    
  //   @media (max-width: 800px) {
  //     display: block;
  //   }
    
  //   @media (max-height: 600px) {
  //     display: block;
  //   }
  // }