
/**
Utils
*/
@import './utils/breakpoints';
@import './utils/variables';

/**
Base
*/
@import './base/reset';
@import './base/typography';
  
// @font-face {
//     font-family: 'Formula Condensed';
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
//     src: local("Formula Condensed Bold"), local("FormulaCondensed-Bold"),
//         url('../../../public/assets/fonts/FormulaCondensed-Bold.woff2') format("woff2");
// }

/*
Shared
*/
@import './shared/links';
@import './shared/type';

/*
General
*/
@import './base/global';

/*
Components
*/
@import './components/header';
@import './components/footer';


/*
Pages
*/
@import './pages/splash';
@import './pages/home';
@import './pages/homepage';
@import './pages/book';