@font-face {
    font-family: 'Formula Condensed';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/FormulaCondensed-Bold.woff2') format('woff2'),
        url('../../../public/assets/fonts/FormulaCondensed-Bold.otf') format('otf');
}
@font-face {
    font-family: 'Formula Condensed';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/FormulaCondensed-Black.woff2') format('woff2'),
        url('../../../public/assets/fonts/FormulaCondensed-Black.otf') format('otf');
}
@font-face {
    font-family: 'Formula Condensed';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/FormulaCondensed-Regular.woff2') format('woff2'),
        url('../../../public/assets/fonts/FormulaCondensed-Regular.otf') format('otf');
}
@font-face {
    font-family: 'Formula Condensed';
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/FormulaCondensed-Light.woff2') format('woff2'),
        url('../../../public/assets/fonts/FormulaCondensed-Light.otf') format('otf');
}
@font-face {
    font-family: 'neue-haas-grotesk-text';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
        url('../../../public/assets/fonts/NHaasGroteskTXPro-55Rg.ttf') format('ttf');
}
@font-face {
    font-family: 'Le Murmure';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src:
        url('../../../public/assets/fonts/Le-Murmure.woff2') format('woff2'),
        url('../../../public/assets/fonts/Le-Murmure.otf') format('otf');
}