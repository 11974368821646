// @include "../utils/breakpoints.scss";
// html {
//   font-size: calc(100vw / 1920 * 10);
// }

body {
    font-family: var(--font-f-condensed);
    font-display: swap;
    // font-size: 12px;
    font-size: var(--font-s-body);
    // line-height: var(--lh-body);
}

  
// Mobile First
h1 {
    font-size: 8rem;
    font-weight: bold;
    line-height: 1.2;
}

h2 {
    font-size: 2.875rem;
}
h3 {
    font-size: 1.75rem;
}
h4 {
    font-size: 1.375rem;
}
h5 {
    font-size: 1rem;
}
h6 {
    font-size: 0.875rem;
}

//   @use 'tablet';
@include tablet {
    body {
        // font-size: 15px;
        // background: red !important;
    }
    h1 {
        font-size: 8rem;
        font-weight: bold;
        line-height: 1.2;
    }

    h2 {
        font-size: 2.875rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.375rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.875rem;
    }

}
@include desktop {
    body {
        font-size: var(--font-s-body);
        font-display: swap;
    }
    h1 {
        font-size: 8rem;
        font-weight: bold;
        line-height: 1.2;
    }

    h2 {
        font-size: 2.875rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.375rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.875rem;
    }
}

