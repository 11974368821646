body {
    line-height: 1.5;
    font-weight: 400;
    color: var(--color-black);
    background: var(--color-offwhite);
}

body.dark {
    background: var(--color-black);
    color: var(--color-white);
}


// Navigation
.nav {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    color: var(--color-white);
    z-index: 10;
    
    --nav-padding: 1.5rem;
    
    @media screen and (min-width: 768px) {
        --nav-padding: 3rem;
    }
    @media screen and (min-width: 1024px) {
        --nav-padding: 5rem;
    }
    
    &-bg {
        background: var(--color-black);
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: left;
        z-index: 4;
    }
    
    header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        z-index: 12;
        background: transparent;

        .toggleMenuClose {
            cursor: pointer;
        }
    }
    .navContainer {
        z-index: 10;
        padding: var(--nav-padding);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-top: 120px;
        padding-bottom: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        @media screen and (min-width: 768px) {
            justify-content: space-between;
        }


        h2 {
            font-size: clamp(6rem, 20vw, 10rem);
            font-weight: bold;
            overflow-y: hidden;
            
            > div {
                overflow-y: hidden;
            }

            .char {
                display: inline-block;
            }
        }
        ul {

            max-width:55rem;

            li {
                font-family: var(--font-f-condensed);
                font-size: 1.15rem;
                position: relative;
                padding-right: 0.5rem;
                margin-right: 0.5rem;
                overflow-y: hidden;
                display: inline-block;
                
                &:not(:last-child)::after {
                    content:"\00b7";
                    position: absolute; 
                    right: 0;
                    top: 0;
                }
                
                @media screen and (min-width: 768px) {
                    padding-right: 1rem;
                    margin-right: 1rem;
                    font-size: 1.25rem;
                    // &:not()::after {
                    //     content:"";
                    // }
                }
                @media screen and (min-width: 1024px) {
                    font-size: 1.5rem;
                    line-height: 1.5;
                }
    
                
                a {
                    text-decoration: none;
                    color: var(--color-white);
                }
            }
        }
        
    }
}

